<template>
  <div class="row_contain" style="padding-top: 46px">
    <van-nav-bar
      :fixed="fixed"
      title="地址"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-address-edit
      :area-list="areaList"
      :show-delete="Sowdelete"
      show-set-default
      show-search-result
      :search-result="searchResult"
      :address-info="AddressInfo"
      :area-columns-placeholder="['请选择', '请选择', '请选择']"
      @save="onSave"
      @delete="onDelete"
    />
  </div>
</template>

<script>
import { areaList } from './area.js';
export default {
  props: {
    id: {},
    type: {}
  },
  data () {
    return {
      Sowdelete: false,
      fixed: true,
      AddressInfo: {},
      areaList: areaList,
      searchResult: [],
    };
  },
  created () {

    if (this.type == '1') {
      //新建地址


    }
    if (this.type == '2') {
      //新建地址
      this.Sowdelete = true;
      this.editAddLogin()

    }
  },
  methods: {
    onClickLeft () {
      this.$router.back()
    },
    async editAddLogin () {
      this.$Toast.loading({ forbidClick: true });

      let value = this.$qs.stringify({
        address_id: this.id
      })
      let res = await this.$http.post('address/get_address', value);
      if (res.data.error_code == 0) {
        this.AddressInfo.name = res.data.data.name;
        this.AddressInfo.areaCode = res.data.data.areaCode;
        this.AddressInfo.tel = res.data.data.phone;
        this.AddressInfo.province = res.data.data.province;
        this.AddressInfo.city = res.data.data.city;
        this.AddressInfo.county = res.data.data.area || '';
        this.AddressInfo.addressDetail = res.data.data.address;
        this.AddressInfo.isDefault = res.data.data.is_default == '1' ? true : false;
        this.AddressInfo.id = res.data.data.address_id;
        this.AddressInfo = Object.assign({}, this.AddressInfo)
        this.$Toast.clear();
        console.log(this.AddressInfo)
      }
    },
    async onSave (content) {
      //console.log(content)
      this.$Toast.loading({ forbidClick: true });


      if (this.type == '1') {

        let value = this.$qs.stringify({

          user_id: this.$store.state.login.userInfo.user_id,
          name: content.name,
          areaCode: content.areaCode,
          phone: content.tel,
          province: content.province,//必须，String，省份
          city: content.city,//必须，String，城市
          area: content.county,//必须，String，区域
          address: content.addressDetail,// 必须，String，详细地址
          is_default: content.isDefault,//必须，String，是否默认1是2不是
        })
        let res = await this.$http.post('address/add_address', value);
        if (res.data.error_code == 0) {
          this.$Toast.clear();
          this.$Toast('创建成功');
          this.$router.back();
        }
      }
      if (this.type == '2') {
        let value = this.$qs.stringify({
          address_id: this.id,
          name: content.name,
          areaCode: content.areaCode,
          phone: content.tel,
          province: content.province,//必须，String，省份
          city: content.city,//必须，String，城市
          area: content.county,//必须，String，区域
          address: content.addressDetail,// 必须，String，详细地址
          is_default: content.isDefault,//必须，String，是否默认1是2不是
        })
        let res = await this.$http.post('address/edit_address', value);
        if (res.data.error_code == 0) {
          this.$Toast.clear();
          this.$Toast('修改成功');
          this.$router.back();
        }
      }

    },
    async onDelete () {
      this.$Toast.loading({ forbidClick: true });
      let value = this.$qs.stringify({
        address_id: this.id
      })
      let res = await this.$http.post('address/delete_address', value);
      if (res.data.error_code == 0) {
        this.$Toast.clear();
        this.$Toast('删除成功');
        this.$router.back();
      }


    }
  },
};
</script>

<style>
</style>